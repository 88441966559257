var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.objectIsEmpty(_vm.data))?_c('CreditPlatformProductLayout',{attrs:{"breadcrumb-items":[
    {
      text: 'Inicio',
      href: '/plataforma-credito',
    },
    {
      text: 'Detalhes Estabelecimento',
      href: ("/plataforma-credito/estabelecimento/detalhes/" + _vm.merchantId),
    },

    {
      text: 'Consulta',
    } ],"merchant-selected-is-required":false,"optin-selected-is-required":false,"merchant":_vm.data.merchant}},[_c('Page',{attrs:{"schedule":_vm.data,"register":_vm.data.register}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }